import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // minWidth: 275,
    // width: "75%",
    // maxWidth: 800,
    // marginLeft: 'auto',
    // marginRight: 'auto'
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  field: {
    marginTop: theme.spacing(2)
  }
}));

export default useStyles;