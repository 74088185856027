import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  MenuItem, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types'
import useStyles from './AutoForm.styles';


const AutoForm = props => {
  const {
    formID = "defaultForm",
    fieldData = [],
    submitHandler,
    submitLabel = "Submit",
    titleLabel = "",
    disabled: isFormDisabled = false,
    hideButton = false,
    onChange = () => { }
  } = props
  const classes = useStyles();
  const [formData, setFormData] = useState({})
  useEffect(() => {
    if (onChange) {
      onChange(formData)
    }
  }, [formData, onChange])
  useEffect(() => {
    if (!formData) {
      const defaultFields = {}
      fieldData.forEach(field => {
        defaultFields[field.key] = (formData && formData[field.key]) || field.default || ""
      })
      setFormData(defaultFields)
    }
  }, [fieldData, formData])

  const textFieldProps = useMemo(() => {
    const result = fieldData.map(field => {
      if (!field || !field.key) {
        console.error('Missing field key parameter: ', field)
        return null
      }
      if (field.type === "callback") {
        let newData = field.callback(formData)
        if (newData) {
          field = {
            key: field.key,
            ...newData
          }
        } else {
          return { key: field.key, disabled: true, label: 'N/A' }
        }
      }
      const key = field.key
      const name = field.name || key || ""
      const autoComplete = field.autoComplete || name
      const label = (field.label || name).charAt(0).toUpperCase() + (field.label || name).slice(1)
      const required = !field.notRequired
      const placeHolder = field.placeHolder || label

      const multiline = field.type === "multiline"

      const value = (formData && formData[key]) || "" //|| placeHolder
      const onChange = event => {
        let target
        if (field.onChange) {
          target = field.onChange(event)
        } else {
          target = event.target.value
        }
        setFormData(data => ({ ...data, [key]: target }))
      }
      const type = field.type

      const textFieldProp = {
        key,
        name,
        autoComplete,
        label: label,
        placeholder: placeHolder,
        // label: (value || (focus === key)) ? label : placeHolder,
        value,
        onChange,
        required,
        type: multiline ? "text" : type,
        multiline,
        disabled: isFormDisabled,
        // onClick: e=>{
        //   if(value===placeHolder){
        //     setClicked(value=>v)
        //   }
        // }
      }
      if (field.pattern) {
        textFieldProp.inputProps = {
          pattern: field.pattern
        }
      }
      if (multiline) {
        if (field.rowsMax) {
          textFieldProp.rowsMax = field.rowsMax
        }
        if (field.rows) {
          textFieldProp.rows = field.rows
        }
      }

      if (type === 'selection') {
        textFieldProp.select = true
        const selection = field.selection
        if (selection && selection.length > 0) {
          textFieldProp.children = selection.map(({ value, label, disabled }) => (
            <MenuItem key={value} value={value} disabled={disabled || isFormDisabled}>
              {value ? label : placeHolder}
            </MenuItem>
          ))
          const enabledSelections = selection.filter(({ disabled }) => !disabled)
          if (enabledSelections.length > 0 && !enabledSelections.map(({ value }) => value).includes(formData[key])) {
            setFormData(data => ({ ...data, [key]: enabledSelections[0].value }))
          }
        }
      }
      return textFieldProp
    })
    return result
  }, [fieldData, formData, isFormDisabled])
  const onSubmit = e => {
    e.preventDefault()
    submitHandler(formData).then(result => {
      if (result.clearData) {
        setFormData({})
      }
    })
  }
  return (

    <form className={classes.form} onSubmit={onSubmit} id={formID}>
      {titleLabel &&
        <div>
          <h1>{titleLabel}</h1>
        </div>
      }
      {
        textFieldProps.map(textFieldProp => (
          // <div>
          <TextField {...textFieldProp} variant="outlined" size="small" className={classes.field} />

          // {/* </div> */}
        ))
      }
      {
        !hideButton &&
        <Button disabled={isFormDisabled} className={classes.submitButton} color="primary" type="submit">{submitLabel}</Button>
      }
    </form>
  )
}

const AutoFormSelectionOptionsPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
})

const AutoFormFieldPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  type: PropTypes.string,
  callback: PropTypes.func,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  notRequired: PropTypes.bool,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  rowsMax: PropTypes.number,
  rows: PropTypes.number,
  selection: PropTypes.arrayOf(AutoFormSelectionOptionsPropType)
})
AutoForm.propTypes = {
  formID: PropTypes.string,
  disabled: PropTypes.bool,
  fieldData: PropTypes.arrayOf(AutoFormFieldPropType),
  onChange: PropTypes.func,
  submitHandler: PropTypes.func,
  submitLabel: PropTypes.string,
  titleLabel: PropTypes.string,
  hideButton: PropTypes.bool
}

export default AutoForm