import AutoForm from '../AutoForm'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, Snackbar, Typography } from '@material-ui/core'
import fieldData from './fieldDefinitions'
import useStyles from './styles'
import { logo } from '../../assets'
import Alert from '@material-ui/lab/Alert';

const apiURL = process.env.REACT_APP_SUPPORT_API || "https://asia-east2-animaetech-central-hub.cloudfunctions.net/support/api"
const FORM_STATES = {
  FORM: 'form',
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error"
}
const Form = props => {
  const [status, setStatus] = useState(FORM_STATES.FORM)
  const [error, setError] = useState("")
  const [timer, setTimer] = useState(null)
  // const [data, setData] = useState({})
  const metaData = null
  const classes = useStyles()
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
        setTimer(null)
      }
    }
  }, [timer])


  const isLoading = status === FORM_STATES.LOADING
  // const showForm = status === 'form' || status === "error"
  const showSuccess = status === FORM_STATES.SUCCESS
  const exitSuccess = () => {
    setStatus(FORM_STATES.FORM)
  }
  const submitHandler = (data) => {
    // console.log(data)
    if (metaData) {
      data.meta = metaData
    }
    setStatus(FORM_STATES.LOADING)
    return axios.post(apiURL + "/request", data)
      .then(response => {
        setStatus(FORM_STATES.SUCCESS)
        setTimer(setTimeout(exitSuccess, 5000))
        return { clearData: true, success: true }
      })
      .catch(error => {
        console.error(error.response || error)
        setStatus(FORM_STATES.ERROR)
        if (error && error.response && error.response.data && error.response.data.displayToUserMessage) {
          setError(error.response.data.displayToUserMessage)
        } else {
          setError("Apologies, we seem to have trouble submitting your support request. Please try again later, or email use directly.")
        }
        return { clearData: false, success: false }
      })
  }
  const submitLabel = isLoading ? "Sending your request..." : "Send"
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.form}>
          <CardContent className={classes.cardContent}>
            <div className={classes.title}>
              <div>
                <img src={logo} width={48} height={48} alt="Animae Technologies Ltd"
                />

              </div>
              <div>
                <Typography variant="h3" component="h2">
                  Contact Support
              </Typography>

              </div>
            </div>
            {/* <hr /> */}
          </CardContent>
          <CardContent className={classes.cardContent}>
            <Typography color="textSecondary">
              We can help you understand our services and solve issues regarding your billing, accounts and data.
            </Typography>
            <Typography gutterBottom color="textSecondary">
              Please fill in the form below and describe who you are and your issue in as much detail as possible
              so that we may help you solve your problem in the shortest amount of time.
            </Typography>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <hr />
          </CardContent>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" component="h3" color="textSecondary">
              Please fill in the following:
            </Typography>
            <AutoForm
              formID="support-form"
              disabled={isLoading}
              // titleLabel=""
              fieldData={fieldData}
              // useButton={false}
              hideButton
              // onChange={setData}
              submitHandler={submitHandler}
            >
              Form
              </AutoForm>
          </CardContent>
          <CardActions>
            <Button
              disabled={isLoading}
              variant="contained"
              className={classes.submitButton}
              color="primary"
              type="submit"
              form="support-form"
            // onClick={e => {
            //   e.preventDefault()
            //   console.log(data)
            //   submitHandler(data)
            // }}
            >{submitLabel}</Button>
          </CardActions>
          <Snackbar open={!!error} autoHideDuration={6000} onClose={e => setError("")}>
            <Alert onClose={e => setError("")} severity="error">
              {error}
            </Alert>
          </Snackbar>
          <Snackbar open={!!showSuccess} autoHideDuration={6000} onClose={exitSuccess}>
            <Alert onClose={exitSuccess} severity="success">
              We have successfully submitted your support request.
            </Alert>
          </Snackbar>
        </div>
      </Card>
    </div>
  )
}

export default Form