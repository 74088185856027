import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: "column",
    textAlign: "center",
    // margin: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(2)
    }
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(2)
    }
  },
  form: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(2)
    }
  },
  submitButton: {
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(0.5),
    //   marginBottom: theme.spacing(0.5)
    // },
    // [theme.breakpoints.up("xl")]: {
    // }
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  card: {
    // background: "radial-gradient(circle at 50% 50%, #dfd9d3, #d3dfd3, #d3d9df)"
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2)
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center"
  },
  cardContent: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(0),
    },
  },
  title: {
    display: "flex",
    color: "#1e75bc",
    // justifyContent: "center",
    alignItems: "center",
    "& img": {
      height: "3rem",
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(1),
        // marginLeft: theme.spacing(0.5)
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: theme.spacing(2),
        // marginLeft: theme.spacing(1)
      }
    }
  }

}))

export default useStyles