import { createContext, useContext } from 'react'

const defaultAuthContext = null
const AuthContext = createContext(defaultAuthContext)

export const AuthProvider = props => {
  const { children } = props
  const value = {}
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const authContext = useContext(AuthContext)

  return authContext
}

export default AuthProvider