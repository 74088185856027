import { Container, createMuiTheme, CssBaseline, makeStyles, responsiveFontSizes, ThemeProvider } from "@material-ui/core";
import AuthProvider from "./Auth";
import Form from "./Form";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1f74ba",
    },
  },
})
theme = responsiveFontSizes(theme)

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}))

function App() {
  const classes = useStyles()
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container className={classes.container} maxWidth="md">
          <Form />
        </Container>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
